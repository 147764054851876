    /*
    Flaticon icon font: Flaticon
    Creation date: 06/12/2017 17:53
    */

    @font-face {
  font-family: "Flaticon";
  src: url("../../assets/fonts/Flaticon.eot");
  src: url("../../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../../assets/fonts/Flaticon.woff") format("woff"),
       url("../../assets/fonts/Flaticon.ttf") format("truetype"),
       url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

    .fi:before{
        display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
    }

    .flaticon-rulers-1:before { content: "\f100"; }
.flaticon-rulers:before { content: "\f101"; }
.flaticon-timer:before { content: "\f102"; }
.flaticon-time:before { content: "\f103"; }
.flaticon-sports-3:before { content: "\f104"; }
.flaticon-sports-2:before { content: "\f105"; }
.flaticon-sports-1:before { content: "\f106"; }
.flaticon-sports:before { content: "\f107"; }
.flaticon-transport:before { content: "\f108"; }

    $font-Flaticon-rulers-1: "\f100";
    $font-Flaticon-rulers: "\f101";
    $font-Flaticon-timer: "\f102";
    $font-Flaticon-time: "\f103";
    $font-Flaticon-sports-3: "\f104";
    $font-Flaticon-sports-2: "\f105";
    $font-Flaticon-sports-1: "\f106";
    $font-Flaticon-sports: "\f107";
    $font-Flaticon-transport: "\f108";
