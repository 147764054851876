

// Responsiveness (Bulma)

// The container horizontal gap, which acts as the offset for breakpoints
$gap: 32px;
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px;
// 960px container + 3rem
$desktop: 960px + (2 * $gap);
// 1152px container + 3rem
$widescreen: 1152px + (2 * $gap);
// 1344px container + 3rem
$fullhd: 1252px + (2 * $gap);



// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: $tablet,
  lg: $desktop,
  xl: $widescreen
);

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem;




// Colors
$white:    #fff;
$gray-100: #f1f1f1;
$gray-200: #eaf9f8;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #544c52;
$gray-800: #343a40;
$gray-900: #2d292c;
$black:    #000;

$grays: ();
$grays: map-merge((
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
), $grays);

$blue:    #104d87;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e6007e;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #edb244;
$green:   #bbba4c;
$teal:    #20c997;
$cyan:    #1b9ec0;

$btn-gradient-color1: #cf1979;
// $btn-gradient-color2: #f95999;
$btn-gradient-color2: $pink;
$btn-gradient-color3: #104d87;
$btn-gradient-color4: #e6007e;
$gradient1: linear-gradient(20deg, rgba($btn-gradient-color2,0) 0%, $btn-gradient-color2 100%);
$gradient2: linear-gradient(20deg, $btn-gradient-color3 0%, rgba($btn-gradient-color3,0) 100%);

$global-colors: ();
$global-colors: map-merge((
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
), $global-colors);

$primary:       $pink;
$secondary:     $blue;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-900;

$theme-colors: ();
$theme-colors: map-merge((
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "lightblue":      $gray-200,
  "dark":       $dark
), $theme-colors);

// Set a specific jump point for requesting color jumps
$theme-color-interval:      8%;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900;
$yiq-text-light: $white;


$body-bg:                   $white;
$body-color:                $gray-900;

$link-color:                theme-color("primary");
$link-decoration:           none;
$link-hover-color:          darken($link-color, 15%);
$link-hover-decoration:     none;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:              true;
$enable-rounded:            true;
$enable-shadows:            false;
$enable-gradients:          false;
$enable-transitions:        true;
$enable-hover-media-query:  false;
$enable-grid-classes:       true;
$enable-print-styles: true;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:              1.5;
$line-height-sm:              1.5;

$border-width:                1px;
$border-color:                $gray-200;

$border-radius:               .25rem;
$border-radius-lg:            .3rem;
$border-radius-sm:            .2rem;

$component-active-color:      $white;
$component-active-bg:         theme-color("primary");

$caret-width:                 .3em;

$transition-base:             all .2s ease-in-out;
$transition-fade:             opacity .15s linear;
$transition-collapse: height .35s ease;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      'Raleway', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.25);
$font-size-sm:                ($font-size-base * .875);

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;

$font-weight-base:            $font-weight-normal;
$line-height-base:            1.5;

$h1-font-size:                $font-size-base * 3.75;
$h2-font-size:                $font-size-base * 2.5;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

$headings-margin-bottom:      ($spacer / 2);
$headings-font-family:        'Permanent Marker', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$headings-font-weight:        500;
$headings-line-height:        1.2;
$headings-color:              inherit;

$display1-size:               6rem;
$display2-size:               5.5rem;
$display3-size:               4.5rem;
$display4-size:               3.5rem;

$display1-weight:             300;
$display2-weight:             300;
$display3-weight:             300;
$display4-weight:             300;
$display-line-height:         $headings-line-height;

$lead-font-size:              ($font-size-base * 1.25);
$lead-font-weight:            300;

$small-font-size:             80%;

$text-muted:                  $gray-600;

$blockquote-small-color:      $gray-600;
$blockquote-font-size:        ($font-size-base * 1.25);

$hr-border-color:             rgba($black, .1);
$hr-border-width:             $border-width;

$mark-padding:                .2em;

$dt-font-weight:              $font-weight-bold;

$kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25);
$nested-kbd-font-weight:      $font-weight-bold;

$list-inline-padding:         .5rem;

$mark-bg:                     #fcf8e3;

$hr-margin-y: $spacer;



// BULMA
//
// Link colors

$link: theme-color('primary');
$link-invert: theme-color('secondary');
$link-visited: theme-color('primary');

$link-hover: theme-color('secondary');
$link-hover-border: theme-color('secondary');

$link-focus: theme-color('secondary');
$link-focus-border: theme-color('secondary');

$link-active: theme-color('primary');
$link-active-border: theme-color('primary');

// Typography

$family-primary: $font-family-base;
