html {
  padding-top: 5rem;
  @include desktop() {
    padding-top: 7rem;
  }
}
html.menu-open {
  overflow: hidden;
}
header.banner {
  position: fixed;
  height: 5rem;
  background: $white;
  width: 100%;
  top: 0;
  z-index: 99;

  svg {
    position: absolute;
    top: 100%;
    margin-top: -1px;
    width: 100%;
    height: 2vw;
    z-index: 1;
    polygon {
      fill: $white;
    }
  }

  @include desktop() {
    height: 7rem;
    svg {
      height: 3vw;
    }
  }

  > .container {
    position: relative;
    z-index: 2;
  }

  .navbar-brand {
    .navbar-item {
      padding: 0;
      font-size: 0;
      white-space: nowrap;
      position: relative;
      top: .45rem;
      height: 4.5rem;
      width: 4.5rem;
      left: .5rem;
      &::before {
        content: '';
        display: block;
        background: transparent url('../../assets/images/roulezrose-logo.png') no-repeat center center;
        background-size: contain;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
      @include desktop() {
        top: .8rem;
        width: 8rem;
        height: 8rem;
        left: 0;
      }
    }
  }
  .navbar-burger {
    margin: 1rem 1rem 0 auto;
    &::after { display: none; }
  }

  .navbar {
    background: transparent;
    .navbar-menu {
      margin-bottom: 0;
      margin-top: 2.3rem;
      > .navbar-item {
        padding: 0;
        align-items: stretch;
        height: 5.7rem;
        margin-top: 0;
        > .navbar-link {
          line-height: 3rem;
          font-weight: 700;
          text-transform: uppercase;
          color: theme-color('dark');
          padding: .5rem 1rem;
          transition: background .3s ease, color .3s ease;
          &::after { display: none; }
        }
        &.has-dropdown > .navbar-link {
          padding-right: 2.5rem;
          &::after {
            display: block;
            border-color: theme-color('primary');
            border-width: 2px;
          }
        }
        &:hover > .navbar-link,
        > .navbar-link:hover {
          color: theme-color('primary');
          background-color: gray('200');
        }
        .navbar-dropdown {
          border: 0;
          background-color: theme-color('primary');
          background-image: linear-gradient(180deg, rgba(theme-color('primary'),1), rgba(#cf1979,1));
          svg { display: none; }
          > .menu-list {
            li.navbar-item {
              text-align: left;
              display: block;
              a {
                background-color: transparent;
                color: $white;
                text-transform: uppercase;
                font-size: .85rem;
                font-weight: 700;
                letter-spacing: .04em;
                margin: 0;
                border-radius: 0;
                border-bottom: 2px solid transparent;
                &::after { display: none; }
                &:hover {
                  border-bottom-color: $white;
                }
              }
            }
          }
        }
      }
    }

    &.nav-main {
      justify-content: space-between;
      .navbar-menu {
        flex-grow: 0;
        > .navbar-item {
          padding-left: 1rem;
          padding-right: 1rem;
          > .navbar-link {
            background-color: transparent;
            transition: none;
          }
          &:hover > .navbar-link,
          > .navbar-link:hover {
            border-bottom: .5rem solid #cf1979;
            padding-bottom: 0;
            padding-top: .5rem;
            &::after {
              margin-top: -0.125rem;
            }
          }
        }
      }
      .navbar-item-randonnees-roller {
        &:hover > .navbar-link,
        > .navbar-link:hover {
          border-bottom-width: 1rem;
          padding-bottom: 0;
          padding-top: 1rem;
          &::after {
            margin-top: 0.125rem;
          }
        }
        > .navbar-link {
          z-index: 2;
          position: relative;
        }
        .navbar-dropdown {
          position: fixed;
          top: 3.25rem;
          z-index: 1;
          margin-top: 1.8vw;
          background-color: theme-color('primary');
          background-color: transparent;
          // $g2: #cf1979;
          $g2: theme-color('secondary');
          background-image: linear-gradient(180deg, rgba(theme-color('primary'),1), rgba($g2,0.8));
          svg {
            margin-top: 1px;
            top: -2vw;
            width: 100%;
            height: 2vw;
            z-index: 1;
            display: block;
            pointer-events: painted;
            polygon {
              fill: theme-color('primary');
            }
          }
          @include desktop() {
            top: 5.75rem;
            margin-top: 2.8vw;
            svg {
              top: -3vw;
              height: 3vw;
            }
          }
          > .menu-list {
            max-width: 1140px;
            margin: 0 auto;
            font-size: 0;
            li.navbar-item {
              display: inline-block;
              vertical-align: bottom;
              font-size: 1rem;
              padding: 0 4rem 0 0;
              border-bottom: 1px solid $white;
              a {
                padding: .5rem 1rem .5rem 0;
                border-bottom: 3px solid $white;
                display: inline-block;
                margin: 0 0 -1px;
                &:hover {
                  border-bottom-color: theme-color('secondary');
                }
              }
            }
            .randos-list-menu {
              display: inline-block;
              width: 100%;
              vertical-align: bottom;
              font-size: 1rem;
              padding: 2rem;
              background-color: #b02e6d;
              background-image: linear-gradient(0deg, rgba(theme-color('primary'),.5), rgba(theme-color('primary'),0));
              margin: 2rem 0;
              box-shadow: 0 4px 12px rgba(theme-color('dark'),.25);
              .title {
                color: $white;
                font-family: $headings-font-family;
                padding-right: 22rem;
                text-transform: uppercase;
                font-weight: 400;
                margin: 0 -2rem 4rem;
                padding-left: 2rem;
                padding-bottom: 1.5rem;
                line-height: 2rem;
                position: relative;
                border-bottom: 2px solid #96275d;
                em {
                  font-family: $font-family-base;
                  font-style: normal;
                }
                .button {
                  width: 22rem;
                  position: absolute;
                  top: 0;
                  right: 2rem;
                  border-radius: 2rem;
                  height: 2rem;
                  line-height: 2rem;
                  padding: 0 1rem;
                  color: $white;
                  background-color: theme-color('secondary');
                  background-image: $gradient2;
                  letter-spacing: .15em;
                  font-size: .75rem;
                  font-weight: 700;
                  font-family: $font-family-base;
                  &:hover {
                    background-color: theme-color('dark');
                  }
                }
              }
              .rando.rando-teaser {
                width: 230px;
                height: 230px;
                border: 0 hidden;
                a {
                  padding: 0;
                  border-radius: 0;
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }

    &.nav-top {
      position: absolute;
      top: 0;
      right: 0;
      min-height: 0;
      height: 2.3rem;
      padding-bottom: 0;
      border-bottom: 1px solid $gray-300;
      @include touch() {
        display: none;
      }
      .navbar-item {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        height: 2.3rem;
        padding: 0;
      }
      .navbar-menu {
        list-style: none;
        margin: 0 1.5rem 0 0;
        li {
          background-color: transparent;
          > .navbar-link {
            color: theme-color('dark');
            line-height: 1;
            font-size: .8rem;
            text-transform: none;
            font-weight: 400;
            padding: .5rem 1rem;
            &::after { display: none; }
          }
          &.navbar-item-adherer {
            > .navbar-link {
              background-color: theme-color('secondary');
              color: $white;
              font-family: $headings-font-family;
              font-size: 1.2rem;
              &::after {
                border-width: 2px;
                border-color: $white;
              }
            }
            &:hover > .navbar-link,
            > .navbar-link:hover {
              background-color: theme-color('primary');
            }
          }
        }
      }
      .nav {
        list-style: none;
        padding: 0 .5rem;
        margin: 0;
        li {
          display: inline-block;
          line-height: 1.8rem;
          padding: 0 .5rem;
          a {
            font-size: $font-size-sm;
            color: theme-color('dark');
            &:hover { color: theme-color('primary'); }
          }
        }
      }
      .button {
        height: 2.3rem;
        padding: 0 1rem;
        border-radius: 0;
        border: 0;
        font-family: $headings-font-family;
        font-weight: 400;
        font-size: 1.2rem;
        &::after { display: none; }
      }
      .field {
        .control {
          .input {
            height: 2.3rem;
            border: none;
            border-radius: 0;
            border-bottom: 1px solid $gray-500;
            box-shadow: none;
            padding: .5rem;
            font-size: .825rem;
            width: 12rem;
            transition: all .3s ease;
            &:hover, &:active, &:focus {
              border-color: theme-color('primary');
            }
          }
          .button {
            background-color: transparent;
            color: theme-color('dark');
            &:hover {
              color: theme-color('primary');
            }
          }
        }
      }
    }

  }

  &, svg, svg polygon, .navbar-brand .navbar-item, .navbar .navbar-menu, .navbar .navbar-menu > .navbar-item, .navbar .navbar-menu > .navbar-item > .navbar-link, .navbar.nav-top {
    transition: all .3s ease;
  }

  html.scrolled & {
    height: 4.5rem;
    box-shadow: 0 0 30px rgba(theme-color('dark'), .5);
    filter: drop-shadow(0 0 6px rgba(theme-color('dark'), .3));
    @include desktop() { height: 5rem; }
    background: rgba($white, 1);
    svg {
      height: 2vw;
      polygon {
        fill: rgba($white, 1);
      }
    }
    .navbar-brand {
      .navbar-item {
        top: .35rem;
        height: 4rem;
        width: 4rem;
        left: .5rem;
        @include desktop() {
          width: 6rem;
          height: 6rem;
          top: .45rem;
          left: 0;
        }
      }
    }

    .navbar {
      .navbar-menu {
        margin-top: 0.5rem;
        > .navbar-item {
          height: 5.5rem;
          > .navbar-link {
            line-height: 2.8rem;
          }
        }
      }

      &.nav-main {
        .navbar-item-randonnees-roller {
          .navbar-dropdown {
            top: 3.25rem;
            margin-top: 2.8vw;
            svg {
              top: -2vw;
              height: 2vw;
            }
          }
        }
      }

      &.nav-top {
        transform: translate( 0, -100% );
        visibility: hidden;
      }

    }
  }

  @include mobile() {

    html.menu-open & {
      height: 5rem;
      background: $white;
      svg {
        height: 2vw;
        polygon {
          fill: $white;
        }
      }
      .navbar-brand {
        .navbar-item {
          top: .45rem;
          height: 4.5rem;
          width: 4.5rem;
          left: .5rem;
        }
      }
    }

    .navbar {
      .navbar-menu {
        margin-top: .5rem;
        background-color: theme-color('primary');
        padding: 0;
        max-height: 100%;
        position: fixed;
        top: 4.5rem;
        overflow: auto;
        bottom: 0;
        width: 100%;
        > .navbar-item {
          border-bottom: 1px solid rgba(theme-color('dark'), .2);
          height: auto !important;
          margin-top: 1.5rem;
          padding: 0 !important;
          > .navbar-link {
            color: $white !important;
            padding-right: 1rem !important;
            text-align: center;
            background-color: rgba(theme-color('dark'), 0.5) !important;
            border-color: transparent !important;
            &:hover { background-color: theme-color('dark'); }
          }
          .navbar-dropdown {
            position: static !important;
            background-color: theme-color('primary') !important;
            background-image: linear-gradient(180deg, rgba(theme-color('primary'),1), rgba(#cf1979,1)) !important;
            > .menu-list {
              li.navbar-item {
                display: block !important;
                border: 0 !important;
                padding: .5rem 1rem !important;
                a {
                  display: block !important;
                  margin: 0 !important;
                  border-bottom: 2px solid transparent !important;
                  &:hover {
                    border-bottom-color: $white !important;
                  }
                }
              }
            }
          }
        }
        .navbar-item-randonnees-roller {
          .navbar-dropdown {
            > .menu-list {
              .randos-list-menu {
                display: block;
                box-shadow: none;
                background: none;
                padding: 0;
                .title {
                  text-align: center;
                  margin: 0 0 4rem;
                  padding: 0 0 1rem;
                  .button { display: none; }
                }
                .rando.rando-teaser {
                  margin-bottom: 2rem;
                }
                &.owl-carousel {
                  .rando.rando-teaser {
                    width: 230px;
                    height: 230px;
                    border: none;
                    margin: 2rem auto 0;
                  }
                }
                .owl-nav {
                  position: absolute;
                  top: -2rem;
                  right: 0;
                  .owl-prev, .owl-next {
                    color: theme-color('primary');
                    display: inline-block;
                    vertical-align: top;
                    line-height: 2rem;
                    width: 2rem;
                    border-radius: 50%;
                    text-align: center;
                    padding: 0;
                    background-color: gray('200');
                    transition: all .3s ease;
                    &:hover {
                      background-color: theme-color('primary');
                      color: $white;
                    }
                  }
                  .owl-next {
                    margin-top: 1rem;
                    margin-left: -.5rem;
                  }
                }
              }
            }
          }
        }
      }
    }

  }

  body.admin-bar & {
    top: 32px;
    .navbar.nav-main .navbar-item-randonnees-roller .navbar-dropdown {
      transform: translate(0,32px);
    }
  }

}
