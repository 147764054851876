
@include tablet() {
  .with-prefix-black,
  .with-prefix-white,
  .with-suffix-black,
  .with-suffix-white {
    position: relative;
  }
  .with-prefix-black,
  .with-prefix-white {
    padding-left: 50px;
  }
  .with-suffix-black,
  .with-suffix-white {
    padding-right: 50px;
  }
  .with-prefix-black::before,
  .with-prefix-white::before,
  .with-suffix-black::after,
  .with-suffix-white::after {
    content: '';
    position: absolute;
    width: 38px;
    height: 21px;
    top: .5em;
  }
  .with-prefix-black::before,
  .with-prefix-white::before {
    left: 0;
  }
  .with-suffix-black::after,
  .with-suffix-white::after {
    right: 0;
  }
  .with-prefix-black::before {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAVAgMAAABXvXQ+AAAADFBMVEUtKSxHcEwtKSwtKSxVwzJ/AAAABHRSTlP/ALwe3+m8vAAAAEhJREFUCNdjCA0NjWMAgQ0MQGYEmKkAYgYwQACqKJJaCAjAxQxvYICpDYMaBTQhhAEGkEWR1BIy9wFM/waGBga4CUgASRShFgDWqCZ9BunCYQAAAABJRU5ErkJggg==');
  }
  .with-prefix-white::before {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAVBAMAAADY/YGeAAAAD1BMVEX///9HcEz///////////9iTY1sAAAABXRSTlP/ALweH50C9GMAAABOSURBVBjTYxCEACMGOFBmgIopIMSYYGIMyACPOiTzVGBiyIAUMWEFFFvB7hNCEYLYK8CABnCoQzOPMvcZoVsKdJ8ChhgTAzaATR2meSoAwScNqM7zVt8AAAAASUVORK5CYII=');
  }
  .with-suffix-black::after {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAVBAMAAADY/YGeAAAAD1BMVEUtKSxHcEwtKSwtKSwtKSwJ+hfqAAAABXRSTlP/ALweH50C9GMAAABKSURBVBjTYzBigANlQQhgUECIMcHEkAE+dUjmqcDEBDEB0WJGKDYzGaLZCwKK6O4DAkZc6tDMc6Su+8DBiG4vyB4GbACbOkzzVAD8rQ4giUtRQgAAAABJRU5ErkJggg==');
  }
  .with-suffix-white::after {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAVBAMAAADY/YGeAAAAD1BMVEX///9HcEz///////////9iTY1sAAAABXRSTlP/ALweH50C9GMAAABKSURBVBjTYzBigANlQQhgUECIMcHEkAE+dUjmqcDEBDEB0WJGKDYzGaLZCwKK6O4DAkZc6tDMc6Su+8DBiG4vyB4GbACbOkzzVAD8rQ4giUtRQgAAAABJRU5ErkJggg==');
  }
}

.roulezrose-roller {
  font-size: 0;
  width: 32px;
  height: 32px;
  display: block;
  position: relative;

  &::after {
    content: '';
    background: transparent url('../../assets/images/roulezrose-roller-blanc-min.png') no-repeat center center;
    background-size: contain;
    display: block;
    position: absolute;
    width: 90%;
    height: 90%;
    top: 4%;
    left: 4%;
  }

  &.round {
    border-radius: 50%;
    background-color: theme-color('secondary');
    &::after {
      width: 60%;
      height: 60%;
      top: 19%;
      left: 22%;
    }
  }
  &.big {
    width: 64px;
    height: 64px;
  }
  &.black::after {
    background-image: url('../../assets/images/roulezrose-roller-noir-min.png');
  }
}



.button {
  border-radius: 3rem;
  height: 2.8em;
  padding: .25em 2.5em;
  text-transform: uppercase;
  font-weight: 700;
  transition: all .3s ease;
  letter-spacing: .08em;
  &::after {
    @extend .fa;
    content: $fa-var-arrow-right;
    margin-left: 1em;
    transition: transform .3s ease;
  }
  &:hover {
    &::after {
      transform: translateX(4px);
    }
  }
  &.is-primary {
    background-color: $btn-gradient-color1;
    background-image: $gradient1;
    background-position: top right;
    background-size: 200% auto;
    &:hover {
      // background-image: $gradient2;
      background-color: theme-color('secondary');
    }
  }
  &.is-text {
    letter-spacing: .15em;
    text-decoration: none !important;
    color: theme-color('primary');
  }
  @include mobile() {
    padding: .25em 1.25em;
    font-size: 1rem;
    letter-spacing: 0 !important;
    white-space: normal;
    &.is-large {
      font-size: 1.1rem;
    }
  }
}


.section-title {
  font-size: $h2-font-size !important;
  text-transform: uppercase;
  letter-spacing: -.04rem;
  em { font-family: $font-family-base; font-style: normal; }
  small { font-size: $h4-font-size; }
  @include touch() {
    text-align: center;
  }
}

.empty {
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: .08em;
  padding: 1rem 0;
}


.popper,
.tooltip {
    position: absolute;
    z-index: 200;
    background: theme-color('light');
    color: theme-color('dark');
    width: 300px;
    border-radius: 2px;
    box-shadow: 0 0 2px rgba(theme-color('dark'),0.5);
    padding: 10px;
    text-align: center;
}
.style5 .tooltip {
    background: theme-color('primary');
    color: $white;
    max-width: 200px;
    width: auto;
    font-size: .8rem;
    padding: .5em 1em;
}
.popper .popper__arrow,
.tooltip .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
}

.tooltip .tooltip-arrow,
.popper .popper__arrow {
    border-color: theme-color('light');
}
.style5 .tooltip .tooltip-arrow {
    border-color: theme-color('primary');
}
.popper[x-placement^="top"],
.tooltip[x-placement^="top"] {
    margin-bottom: 5px;
}
.popper[x-placement^="top"] .popper__arrow,
.tooltip[x-placement^="top"] .tooltip-arrow {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
}
.popper[x-placement^="bottom"],
.tooltip[x-placement^="bottom"] {
    margin-top: 5px;
}
.tooltip[x-placement^="bottom"] .tooltip-arrow,
.popper[x-placement^="bottom"] .popper__arrow {
    border-width: 0 5px 5px 5px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
}
.tooltip[x-placement^="right"],
.popper[x-placement^="right"] {
    margin-left: 5px;
}
.popper[x-placement^="right"] .popper__arrow,
.tooltip[x-placement^="right"] .tooltip-arrow {
    border-width: 5px 5px 5px 0;
    border-left-color: transparent;
    border-top-color: transparent;
    border-bottom-color: transparent;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
}
.popper[x-placement^="left"],
.tooltip[x-placement^="left"] {
    margin-right: 5px;
}
.popper[x-placement^="left"] .popper__arrow,
.tooltip[x-placement^="left"] .tooltip-arrow {
    border-width: 5px 0 5px 5px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    right: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
}
.tooltip .title {
  font-size: 1.25rem;
  font-weight: 700;
  color: theme-color('dark');
}
.style5 .tooltip .title {
  color: $white;
}
.tooltip .event {
  font-size: .75rem;
  margin-bottom: .5rem;
  &:last-child { margin-bottom: 0; }
  border-left: 5px solid transparent;
  text-align: left;
  padding: 0 1rem;
  border-bottom: 1px solid rgba(0,0,0,.05) !important;
  .title {
    font-size: 1rem;
    margin-bottom: .5rem;
  }
  .description {
    display: block;
  }
  .date {
    display: block;
    font-size: .85rem;
    font-style: italic;
  }
}


// @include keyframes(home-banner-kms) {
//   0%   {
//     box-shadow: 2px -2px 0 theme-color('primary'), 2px -2px 0 theme-color('primary');
//   }
//   100% {
//     box-shadow: 2px -2px 0 theme-color('primary'), 10px -10px 0 rgba(theme-color('primary'), 0);
//   }
// }

@keyframes home-banner-kms {
  0% {
    box-shadow: 2px -2px 0 theme-color('primary'), 2px -2px 0 theme-color('primary');
  }
  30%, 100% {
    box-shadow: 2px -2px 0 theme-color('primary'), 10px -10px 0 rgba(theme-color('primary'), 0);
  }
}

#home-banner {
  background-repeat: no-repeat;
  background-color: theme-color('dark');
  background-size: cover;
  background-position: center center;
  text-align: center;
  position: relative;

  .hero-body {
    padding-bottom: 12rem;
  }

  svg {
    position: absolute;
    bottom: -1px;
    width: 100%;
    height: 3vw;
    z-index: 1;
    polygon {
      fill: $white;
    }
  }

  .title,
  .subtitle {
    font-family: $font-family-base;
    font-size: $h1-font-size;
    color: theme-color('dark');
    margin-bottom: 0;
    display: block;
    margin-top: 0;
    > span {
      background-color: $white;
      padding: .25rem 2rem .75rem;
      display: inline-block;
    }
  }

  .title {
    font-weight: 900;
    text-transform: uppercase;
    text-shadow: 2px -2px 0 theme-color('primary');
    > span {
      position: relative;
      .km {
        position: absolute;
        left: 100%;
        margin-left: 1rem;
        bottom: 1.5rem;
        width: 8rem;
        background-color: theme-color('dark');
        color: $white;
        font-size: 1.5rem;
        padding: .3rem 0 .5rem;
        text-shadow: none;
        font-weight: 700;
        box-shadow: 2px -10px 0 theme-color('primary');
        // @include animation('home-banner-kms .5s infinite 0');
        animation: 3s ease-in 0s infinite normal both home-banner-kms;

        b {
          font-size: .65rem;
          position: relative;
          left: 3px;
          top: -.3rem;
        }
        small {
          display: block;
          font-size: .65rem;
          margin-top: .3rem;
        }
      }
    }
  }

  .subtitle {
    font-weight: 300;
    text-transform: lowercase;
    margin-top: -.875rem;
    font-family: $headings-font-family;
    font-size: 2.75rem;
    letter-spacing: .1em;
  }

  .button {
    margin-top: 7rem;
  }


  @include mobile() {
    .hero-body {
      padding: 4.5rem 1.5rem;
    }
    svg {
      bottom: -1px;
      height: 7vw;
    }
    .title,
    .subtitle {
      font-size: 2.75rem;
    }
    .title {
      > span .km {
        position: static;
        display: block;
        margin: 10px -2rem -1rem;
        width: auto;
      }
    }
    .subtitle {
      font-size: 1.75rem;
      padding: 2rem 0;
      > span {
        background-color: rgba($white,.75);
      }
    }
  }

}


#home-agenda {

  .randos-next {
    position: relative;
    z-index: 12;
    .section-title {
      margin-top: auto;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
    .randos {
      border-bottom: 1px solid theme-color('dark');
    }
    .rando-mini {
      margin-top: -5rem;
      padding-bottom: 0;
    }
  }

  @include mobile() {
    padding: 4.5rem 0;
    .randos-next {
      .rando-mini {
        margin-top: .5rem;
      }
    }
  }

}


#home-news {
  background: theme-color('secondary') url('../../assets/images/pattern-news-min.png') repeat left top;
  padding: ( $spacer * 8 ) 0 ( $spacer * 6 );

  .section-title {
    color: $white;
    text-align: center;
  }

  .news {
    max-width: 960px;
    @include mobile() {
      max-width: 450px;
      overflow: hidden;
    }
    margin: 0 auto 2rem;
  }

}


#home-gallery {
  padding: .75rem 0;
  .tile.is-parent {
    padding: 0 .75rem;
  }

  .is-vertical-centered {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    .body {
      padding: 4.5rem 1.5rem;
      width: 100%;
      &.mini {
        padding: 4.5rem 6rem;
      }
      @include until(1400px) {
        &.mini {
          padding: 4.5rem 2rem;
        }
      }
      h2, h3, h4 {
        text-align: center;
        font-family: $font-family-base;
      }
      h2 {
        font-size: 1.5rem;
        font-weight: 700;
        text-transform: uppercase;
        em { font-style: normal; font-weight: 300; }
      }
      p {
        text-align: justify;
      }
    }
  }

  .gallery-item {
    height: 100%;
    position: relative;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    @include mobile() {
      min-height: 120px;
      &.gallery-solo {
        min-height: 180px;
      }
    }
    .content-text {
      color: $white;
      background-color: rgba(theme-color('dark'),.5);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      padding: 1rem;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 100%;

      opacity: 0;
      transform: scale(1.2);
      transition: all .3s ease;

      > * { width: 100%; display: block; }
    }
    .content-link {
      color: $white;
      background-color: rgba(theme-color('primary'),.7);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      padding: 1rem;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 100%;

      opacity: 0;
      transform: scale(1.2);
      transition: all .3s ease;

      > * { width: 100%; display: block; }
    }
    cursor: pointer;
    &.without-link { cursor: help; }
    &:hover {
      .content-text, .content-link {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  @include desktop-only() {
    .tile.photo-solo {
      width: 20%;
    }
    .tile.photo-duo,
    .tile.content-text,
    .tile.content-staff,
    .tile.content-stats {
      width: 40%;
    }
  }

}

.block-staff {
  padding-bottom: 13rem !important;
  background: $white url('../../assets/images/roulezrose-devenez-staffeur.jpg') no-repeat center bottom;
  background-size: contain;
  h2 {
    font-weight: 300;
    max-width: 18rem;
    margin: 0 auto 2rem;
    font-family: $font-family-base;
    font-size: 1.5rem;
    text-transform: uppercase;
    strong { font-family: $headings-font-family; font-weight: 400; }
  }
}

.block-stats {
  h2 {
    max-width: 18rem;
    font-size: 1.5rem;
    em { font-size: 1em; }
    text-transform: uppercase;
    margin: 0 auto 2rem;
  }
  .title {
    font-family: $headings-font-family;
    font-size: 2rem;
    margin: 0;
    font-weight: 400;
    color: theme-color('dark');
  }
  .desc {
    font-size: .75rem;
  }
  .stat {
    text-align: center;
    border-radius: 2px;
    border: 2px dashed theme-color('dark');
    padding: .75rem;
    &.stat-randos {
      border-color: theme-color('info');
      .title { color: theme-color('info'); }
    }
    &.stat-participants {
      border-color: theme-color('secondary');
      .title { color: theme-color('secondary'); }
    }
  }

  .page-outro & {
    background-color: gray('100');
    height: 100%;
    padding: 3rem;
  }
}



.agenda-next {

  background-color: #eaf9f8;
  text-align: center;
  margin-top: 2 * $spacer;
  margin-bottom: -3 * $spacer;
  padding: 1.5rem 3rem 2.5rem;
  box-shadow: 0 10px 16px -2px rgba(theme-color('dark'), .3);
  z-index: 20;

  &, .agenda-block, .section-title { position: relative; }
  .agenda-block, .section-title { z-index: 3; }
  .section-title {
    font-family: $font-family-base;
    font-weight: 700;
    color: theme-color('secondary');
    font-size: ( $h1-font-size * .5 ) !important;
    small { display: block; font-weight: 300; color: theme-color('dark'); }
    @include mobile() {
      text-align: left;
      padding-right: 3rem;
    }
  }
  .agenda-block { margin-top: 1rem; }
  .month-name { border-right: 1px solid theme-color('dark'); }
  .days {
    .month-day {
      padding: .75rem 0 0;
      text-align: center;
      .num {
        display: block;
      }
      .dot {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: top;
        margin: 0 1px;
      }
    }
  }
  > .fa {
    color: rgba(0,0,0,.03);
    position: absolute;
    z-index: 1;
    font-size: 10rem;
    left: 2rem;
    top: 1rem;
  }

  .agenda-controls {
    position: absolute;
    right: 2rem;
    top: 1rem;
    z-index: 3;
    font-size: 0;
    a {
      color: $white;
      background-color: theme-color('secondary');
      display: inline-block;
      font-size: 1.25rem;
      padding: .3rem;
      &:hover {
        background-color: theme-color('primary');
      }
    }
  }

  &.loading {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      background-color: rgba(theme-color('primary'),.7);
      background-repeat: no-repeat;
      background-image: url(../../assets/images/loading.gif);
      background-position: center center;
      z-index: 20;
    }
  }

  @include mobile() {
    .month-name { border-right: hidden; }
  }

}


.modals-wrapper {
  display: none;
}

html.stop-scroll {
  overflow: hidden;
  > body { overflow: scroll; }
}






.text-niveau-vert { color: $green; }
.text-niveau-bleu { color: $cyan; }
.text-niveau-orange { color: $yellow; }
.text-niveau-noir { color: $black; }
.text-roulez-rose { color: theme-color('primary'); }
