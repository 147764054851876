
#pi-banner {
  background-repeat: no-repeat;
  background-color: theme-color('dark');
  background-size: cover;
  background-position: center center;
  text-align: center;
  position: relative;

  svg {
    position: absolute;
    bottom: -1px;
    width: 100%;
    height: 3vw;
    z-index: 1;
    polygon {
      fill: $white;
    }
  }

  .title,
  .subtitle {
    color: theme-color('dark');
    margin-bottom: 0;
    display: block;
    margin-top: 0;
    > span {
      background-color: $white;
      padding: .25rem 2rem;
      display: inline-block;
    }
  }

  .title {
    font-family: $font-family-base;
    font-size: $h1-font-size;
    font-weight: 900;
    text-transform: uppercase;
    text-shadow: 2px -2px 0 theme-color('primary');
  }

  .subtitle {
    font-family: $headings-font-family;
    font-size: $h2-font-size;
    font-weight: 300;
    text-transform: lowercase;
    margin-top: -.875rem;
    letter-spacing: .1em;
  }

  @include mobile() {
    .hero-body {
      padding: 3rem 0;
    }
    svg {
      bottom: -1px;
      height: 7vw;
    }
    .title,
    .subtitle {
      font-size: 2.25rem;
    }
    .subtitle {
      font-size: 1.55rem;
      padding: 2rem 0;
      > span {
        background-color: rgba($white,.75);
      }
    }
  }

}

#breadcrumbs {
  margin-top: 1rem;
  font-size: .75rem;
  // text-transform: uppercase;
  letter-spacing: .12em;
  @include mobile() {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

body#tinymce,
#main-container {
  padding-top: 3rem;
  padding-bottom: 3rem;

  h2 {
    margin-bottom: 1rem;
    margin-top: 2.5rem;
    em {
      font-family: $font-family-base;
      font-weight: 300;
      text-transform: uppercase;
      font-style: normal;
      font-size: .75em;
    }
  }
  h3, h4, h5, h6 {
    font-family: $font-family-base;
    margin-top: 1.5rem;
  }
  h3 {
    margin-top: 2rem;
    strong {
      font-family: $headings-font-family;
      font-weight: 400;
      text-transform: uppercase;
      font-style: normal;
    }
  }

  ul,
  ol {
    padding-left: 2rem;
    li {
      list-style:none;
      margin-bottom: .5rem;
      &::before {
        content: '';
        background-color: theme-color('primary');
        color: $white;
        border-radius: 50%;
        height: .5em;
        width: .5em;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        font-weight: 700;
        margin-right: .5em;
      }
    }
  }

  ol {
    counter-reset: li;
    li {
      &::before {
        content: counter(li);
        counter-increment: li;
        height: 1.5em;
        width: 1.5em;
        line-height: 1.3em;
      }
    }
  }

  blockquote {
    margin: 0 2rem 1rem;
    padding: 2rem;
    padding-left: 6rem;
    padding-right: 6rem;
    background-color: gray('200');
    border-bottom: 4px solid rgba(theme-color('dark'), .08);
    position: relative;
    font-size: 1.35rem;
    font-weight: 300;
    color: rgba(theme-color('dark'), .5);
    cite {
      margin-top: .5rem;
      text-align: right;
      font-style: italic;
    }
    &::before,
    &::after {
      @extend .fa;
      content: $fa-var-quote-right;
      position: absolute;
      font-size: 2.85rem;
      color: rgba(theme-color('dark'), .08);
    }
    &::before {
      content: $fa-var-quote-left;
      left: 1.8rem;
      top: 1.8rem;
    }
    &::after {
      content: $fa-var-quote-right;
      right: 1.8rem;
      bottom: 1.8rem;
    }
  }

  .mark, mark {
    background-color: gray('200');
  }

  .lead {
    font-size: 1.5rem;
  }
  .lead-block {
    font-size: 1.5rem;
    background-color: theme-color('primary');
    color: $white;
    padding: 1.5rem;
    p:last-child { margin-bottom: 0; }
  }

  .page-intro, .page-outro {
    text-align: center;
    margin: 0 auto;
    padding: 0 1rem;
    font-size: 1.5rem;
    font-weight: 300;
    max-width: 900px;
  }
  .page-intro {
    margin-bottom: 4.5rem;
  }
  .page-outro {
    margin-top: 4.5rem;
  }


  .alert {
    padding: 2rem;
    margin: 0 0 3rem;
    text-align: center;
    &.alert-warning {
      color: $white;
      background-color: theme-color('warning');
    }
    &.alert-danger {
      color: $white;
      background-color: theme-color('danger');
    }
    &.alert-success {
      color: $white;
      background-color: theme-color('success');
    }
  }

  @include mobile() {
    > .columns {
      margin-left: 0;
      margin-right: 0;
    }
    .button {
      padding: 1.75em 1.25em;
      font-size: 1rem;
      letter-spacing: 0 !important;
      white-space: normal;
      line-height: 1.2rem;
      width: 100%;
    }
  }

}

#main-container {

  > .columns > .column.main {
    > .elementor {
      margin: -10px -10px 0 -10px;
    }
  }

  &.randos {
    max-width: 1360px;
    margin: 0 auto;
  }

}



.list-archive {
  &.is-variable.is-5 {
    > .column {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      padding-bottom: 1.25rem;
      padding-left: var(--columnGap);
      padding-right: var(--columnGap);
      padding-bottom: var(--columnGap);
    }
  }
  .tile-teaser {
    box-shadow: 0 3px 10px -2px rgba(theme-color('dark'), .2);
    &:hover {
      box-shadow: 0 8px 12px -2px rgba(theme-color('dark'), .5);
    }
  }

  // Biais
  @include tablet() {
    > .column {
      &:first-child {
        body.archive & { padding-top: 2rem; }
      }
    }
    .tile-teaser {
      height: 100%;
      transform: skew(0,-2deg) translate3d(0,0,0);
      overflow: hidden;
      backface-visibility: hidden;
      > .post,
      > .page,
      > .rando {
        transform: skew(0,2deg) translate3d(0,-.6rem,0);
        backface-visibility: hidden;
        .texte {
          padding: 2rem 2rem .5rem;
        }
      }
    }
  }

}



#statistiques-intro {
  margin-bottom: 4.5rem;

  .stat {

    .title {
      font-family: $headings-font-family;
      font-size: 2.5rem;
      margin: 0;
      font-weight: 400;
      color: theme-color('dark');
      text-shadow: 4px 4px 0 gray('200');
    }
    .intro {
      font-family: $headings-font-family;
      margin-top: -2rem;
      transform: rotate(-10deg);
      margin-bottom: 2.35rem;
      margin-left: -3rem;
      font-size: 1.25rem;
      line-height: 10px;
    }
    .desc {
      font-size: 1.05rem;
      line-height: 1.1rem;
      opacity: .5;
    }

    text-align: center;
    border-radius: 50%;
    height: 160px;
    border: 8px solid theme-color('dark');
    box-shadow: 4px 4px 0 4px gray('200');
    padding: .75rem;
    width: 160px;
    margin: 0 auto;
    &, .title, .intro, .desc { transition: all .3s ease; }
    &:hover {
      color: $white;
      .title {
        color: $white !important;
        text-shadow: 4px 4px 0 rgba(255,255,255,.2);
      }
      .intro {
        transform: rotate(-5deg) translate(5%,0);
      }
      .desc { opacity: 1; }
    }

    &.stat-randos1 { border-color: theme-color('info'); .intro, .title { color: theme-color('info'); } &:hover { background-color: theme-color('info'); } }
    &.stat-randos2 { border-color: theme-color('warning'); .intro, .title { color: theme-color('warning'); } &:hover { background-color: theme-color('warning'); } }
    &.stat-randos3 { border-color: theme-color('success'); .intro, .title { color: theme-color('success'); } &:hover { background-color: theme-color('success'); } }
    &.stat-participants1 { border-color: theme-color('info'); .intro, .title { color: theme-color('info'); } &:hover { background-color: theme-color('info'); } }
    &.stat-participants2 { border-color: theme-color('warning'); .intro, .title { color: theme-color('warning'); } &:hover { background-color: theme-color('warning'); } }
    &.stat-participants3 { border-color: theme-color('success'); .intro, .title { color: theme-color('success'); } &:hover { background-color: theme-color('success'); } }
  }

}

#statistiques-charts {
  margin: 3rem 0;
  .chart-control {
    position: relative;
    h2 {
      font-family: $font-family-base;
      text-transform: uppercase;
      font-size: 1.75rem;
      strong {
        font-weight: 400;
        font-family: $headings-font-family;
        @include mobile() {
          display: block;
        }
      }
      small {
        display: block;
      }
    }
    .nav {
      position: absolute;
      top: .25rem;
      right: 0;
      padding: 0 .5rem;
      background-color: gray('800');
      a {
        color: theme-color('light');
        display: inline-block;
        padding: 0.5rem;
        &:hover { color: theme-color('primary'); }
        &.disabled { color: gray('600') !important; pointer-events: none; }
      }
    }
    &.chart-niv {
      padding: 2rem;
      background-color: gray('200');
      max-width: 500px;
      height: 100%;
      margin: 0 0 0 auto;
      h2 { margin-top: .5rem; }
    }
  }
}


#statistiques-list {
  h2 {
    font-family: $font-family-base;
    text-transform: uppercase;
    font-size: 1.75rem;
    @include mobile() {
      margin-bottom: 2.5rem;
    }
    strong {
      font-weight: 400;
      font-family: $headings-font-family;
      @include mobile() {
        display: block;
      }
    }
    small {
      display: block;
    }
  }

  .controls {
    font-size: 0;
    .button {
      border-radius: 0;
      padding: 0 1.25em;
      margin: 0;
      font-size: .9rem;
      letter-spacing: 0;
      width: 12.5%;
      &:first-child { border-bottom-left-radius: 3rem; border-top-left-radius: 3rem; }
      &:last-child { border-bottom-right-radius: 3rem; border-top-right-radius: 3rem; }
      &::after { display: none; }
      &.is-link {
        background-color: theme-color('secondary');
        color: $white;
      }
      &.selected {
        pointer-events: none;
        background-color: theme-color('primary') !important;
        color: $white !important;
        box-shadow: 0 0 8px rgba(gray('800'),.5) inset;
      }
    }
    .select { width: 100%; height: 2.25rem; font-size: .9rem; }
    select {
      width: 100%;
      // font-size: .9rem;
      // height: auto;
      // padding: .55rem 1rem;
      // border-color: gray('600');
    }
    @include mobile() {
      display: none;
    }
  }

  .header {
    margin-top: 1rem;
    height: 4rem;
    line-height: 2rem;
    text-align: center;
    background-color: gray('400');
    color: $white;
    font-weight: 700;
    .left { text-align: left; }
    .sort {
      cursor: pointer;
      &:hover {
        background-color: theme-color('primary');
      }
      &.asc,
      &.desc {
        &::after {
          @extend .fa;
          margin-left: .5rem;
        }
      }
      &.asc {
        &::after {
          content: $fa-var-arrow-up;
        }
      }
      &.desc {
        &::after {
          content: $fa-var-arrow-down;
        }
      }
    }
  }

  ul.list {
    padding: 0;
    li {
      text-align: center;
      background-color: $white;

      &:nth-child(2n) { background-color: gray('100'); }
      &::before { display: none; }

      .picto {
        display: inline-block;
        vertical-align: middle;
        line-height: 30px;
        font-weight: 700;
        font-size: .9rem;
        padding-left: 30px;
        text-transform: uppercase;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 20px auto;
        &.picto-petite { background-image: url('../../assets/images/picto-fleche-petite-noir.png'); }
        &.picto-grande { background-image: url('../../assets/images/picto-fleche-grande-noir.png'); }
        &.picto-double { background-image: url('../../assets/images/picto-fleche-double-noir.png'); }
      }
      .type {
        .niveau {
          width: 8px;
          height: 8px;
          margin: 0 0 0 .5rem;
          border-radius: 50%;
          display: inline-block;
          vertical-align: middle;
          &.niveau-vert { background-color: theme-color('success'); }
          &.niveau-bleu { background-color: theme-color('info'); }
          &.niveau-orange { background-color: theme-color('warning'); }
          &.niveau-noir { background-color: theme-color('dark'); }
        }
      }
      .note {
        font-weight: 400;
        font-size: .85rem;
        text-align: left;
        p { margin: 0; }
        @include mobile() {
          text-align: center;
        }
      }
      .unit { font-weight: 300; text-transform: uppercase; font-size: .7rem; position: relative; top: -5px; }

      .annule {
        font-family: $headings-font-family;
        &::after {
          content: ':(';
          transform: rotate(90deg);
          display: inline-block;
          margin-left: 14px;
          vertical-align: middle;
        }
      }

      &[data-annule="1"] {
        opacity: .5;
      }
    }
  }

}


.partenaires-list {

  .item {
    background-color: $white;
    box-shadow: 0 10px 16px -4px rgba( theme-color('dark'), .2 );
    height: 100%;
    position: relative;
    .visuel {
      padding: 100% 0 0;
      position: relative;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        max-height: 80%;
        width: auto;
        max-width: 80%;
        z-index: 1;
      }
    }
    a {
      position: absolute;
      background-color: rgba( theme-color('primary'), .8 );
      background-image: $gradient2;
      background-position: left top;
      background-size: 120% 280%;
      background-repeat: no-repeat;
      color: $white;
      font-weight: 700;
      top: 0; right: 0; bottom: 0; left: 0;
      opacity: 0;
      padding: 2rem 1rem;
      text-align: center;
      text-transform: uppercase;
      font-size: 1.25rem;
      transition: opacity .3s ease;
      z-index: 2;
      i {
        display: block;
        margin-top: .5rem;
        text-align: center;
      }
    }
    &:hover { a { opacity: 1; } }
    .nom {
      font-family: $headings-font-family;
      background-color: gray('200');
      color: theme-color('secondary');
      display: block;
      text-align: center;
      padding: .75rem 1rem;
      font-size: 1.2rem;
      line-height: 3rem;
      height: 4.5rem;
      span {
        line-height: 1rem;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

}


body.error404,
body.archive,
body.search-results {
  .search-form {
    text-align: center;
    margin: 0 0 6rem;
    .search-field {
      line-height: 3rem;
      padding: 0 2rem;
      border-radius: 2rem;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: .05em;
      border: 1px solid gray('400');
      width: 70vw;
      max-width: 640px;
      &:hover, &:focus { border-color: theme-color('primary'); }
    }
    .search-submit {
      @extend .button.is-primary;
      line-height: 3rem;
      padding: 0 2rem;
      border-radius: 2rem;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: .05em;
      border: 1px solid transparent;
      cursor: pointer;
      margin-left: -4rem;
    }
    .search-field,
    .search-submit {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.randos-month {

  .randos-month__title {
    margin-bottom: 1.5rem !important;
    margin-top: 3rem !important;
    span {
      transition: transform .3s ease;
    }
    small {
      font-family: $font-family-base;
      font-size: 1em;
      color: gray('200');
      transition: color .3s ease;
    }
  }
  .randos-month__list {
    padding-left: 1rem;
    padding-right: 1rem;
    > .columns {
      justify-content: center;
    }
  }

  &.onscreen {
    .randos-month__title {
      span {
        transform: scale(1.4) rotate(-5deg);
      }
      small {
        color: theme-color('primary');
      }
    }
  }

  @include desktop() {

    position: relative;
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 60px;
      height: 57px;
      background: transparent url('../../assets/images/roulezrose-roller-bleu-min.png') no-repeat center center;
      background-size: contain;
    }
    &::before {
      top: -18rem;
      right: 6rem;
    }
    &::after {
      top: -2.25rem;
      left: 15rem;
      transform: scale3d(-1,1,1);
    }

    .randos-month__title {
      margin-bottom: 6rem !important;
      margin-top: 5.65rem !important;
      position: relative;
      span {
        background-color: $white;
        position: relative;
        z-index: 2;
        display: inline-block;
        padding: 0 2rem;
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 45%;
        height: calc( 8.25rem + 145px - 0.75rem );
        border-color: gray('200');
        border-width: .75rem;
        border-style: solid;
      }
      &::before {
        right: 50%;
        top: 1.25rem;
        border-right: 0;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
      }
      &::after  {
        left: 50%;
        bottom: 1rem;
        border-left: 0;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
    .randos-month__list {
      position: relative;
      > .columns {
        justify-content: start;
        padding-right: 6rem;
        padding-left: 6rem;
        > .column {
          background-color: $white;
          position: relative;
          z-index: 3;
          // &:nth-child(3n+1),
          // &:first-child {
          //   > .rando-teaser {
          //     margin-left: 0;
          //   }
          // }
          // &:nth-child(3n) {
          //   > .rando-teaser {
          //     margin-right: 0;
          //   }
          // }
        }
      }
    }

    &:first-child {
      &::before { display: none; }
      .randos-month__title::after { display: none; }
    }

  }

  // &:last-child {
  //   .randos-month__title::after { display: none; }
  // }
  // &:nth-child(2n+1) {
  //   .randos-month__title::before {
  //     border-bottom-left-radius: 10px;
  //     border-top-left-radius: 10px;
  //   }
  //   .randos-month__title::after {
  //     border-bottom-right-radius: 10px;
  //     border-top-right-radius: 10px;
  //   }
  // }
  // &:nth-child(2n) {
  //   .randos-month__title::before  { border-bottom-left-radius: 10px; }
  //   .randos-month__title::after   { border-top-right-radius: 10px; }
  // }

}
