
#footer-asso {
  background: theme-color('lightblue');
  @include desktop() {
    background: theme-color('lightblue') url('../../assets/images/roulezrose-bg-partenaires.jpg') no-repeat center bottom;
  }
  padding-bottom: 6rem;

  .hero {
    .hero-body {
      max-width: 100%;
      @include widescreen() { max-width: 1100px; }
      border-bottom: 1px solid rgba(theme-color('dark'), .2);
      padding-bottom: 5rem;
      padding-top: 5rem;
    }
    &:last-child {
      .hero-body { border-bottom: hidden; }
    }
  }
  .section-title {
    margin-bottom: 3rem;
  }
  .has-text-centered .button {
    margin-top: 2rem;
  }

  #footer-adhesion {

    .columns {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .visuel {
      position: relative;
      img {
        border-radius: 50%;
        border: 15px solid #fff;
        max-width: 200px;
      }
      .roulezrose-roller {
        position: absolute;
        top: -3%;
        left: 3%;
        box-shadow: 2px -1px 0 theme-color('primary');
        background-color: theme-color('secondary');
      }
    }

  }

  #footer-partenaires {

    .owl-carousel {
      padding: 0 2rem;
      .owl-prev, .owl-next {
        position: absolute;
        top: 50%;
        margin-top: -1rem;
        width: 2rem;
        height: 2rem;
        line-height: 1.6rem;
        font-size: 1rem;
        border: .2rem solid theme-color('primary');
        color: theme-color('primary');
        border-radius: 50%;
        text-align: center;
        @include desktop() {
          width: 2.5rem;
          height: 2.5rem;
          margin-top: -1.25rem;
          line-height: 2.1rem;
          font-size: 1.2rem;
        }
      }
      .owl-prev {
        left: -.5rem;
        @include desktop() { left: -1rem; }
      }
      .owl-next {
        right: -.5rem;
        @include desktop() { right: -1rem; }
      }
    }

    .owl-item {
      padding: 0 1.75rem 2rem;
    }

    .item {
      padding: 100% 0 0;
      background-color: $white;
      box-shadow: 0 10px 16px -4px rgba( theme-color('dark'), .2 );
      position: relative;
      height: 100%;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        max-height: 80%;
        width: auto;
        max-width: 80%;
        z-index: 1;
      }
      a {
        position: absolute;
        background-color: rgba( theme-color('primary'), .8 );
        background-image: $gradient2;
        background-position: left top;
        background-size: 120% 280%;
        background-repeat: no-repeat;
        color: $white;
        font-weight: 700;
        top: 0; right: 0; bottom: 0; left: 0;
        opacity: 0;
        padding: 1rem;
        text-align: center;
        text-transform: uppercase;
        font-size: .65rem;
        transition: opacity .3s ease;
        z-index: 2;
        i {
          display: block;
          margin-top: .5rem;
          text-align: center;
        }
      }
      &:hover { a { opacity: 1; } }
    }

  }

}


#footer-menu {

  position: relative;
  .container {
    position: relative;
    z-index: 2;
  }

  @include desktop() {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: transparent url('../../assets/images/roulezrose-bg-footer.png') no-repeat center top;
    }
  }

  .main {
    color: $white;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2d292c+0,253141+100 */
    background: #2d292c; /* Old browsers */
    background: -moz-linear-gradient(top, #2d292c 0%, #253141 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #2d292c 0%,#253141 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #2d292c 0%,#253141 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2d292c', endColorstr='#253141',GradientType=0 ); /* IE6-9 */

    padding: 3rem 0;

    > .container > .columns > .column:first-child {
      padding-bottom: 160px;
      background: transparent url('../../assets/images/roulezrose-label-club-ffrs-2018.png') no-repeat center bottom;
      background-size: 80% auto;
    }

    @include mobile() {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    @include desktop() {
      .container {
        &::before {
          content: '';
          display: block;
          width: 50%;
          height: 190%;
          left: 25%;
          top: -60%;
          position: absolute;
          z-index: 1;
          background: transparent url('../../assets/images/roulezrose-roller-rose-comic.png') no-repeat center center;
          background-size: contain;
        }
      }
    }
  }
  .second {
    color: $white;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1d2735+0,1a2a3c+100 */
    background: #1d2735; /* Old browsers */
    background: -moz-linear-gradient(top, #1d2735 0%, #1a2a3c 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #1d2735 0%,#1a2a3c 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #1d2735 0%,#1a2a3c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d2735', endColorstr='#1a2a3c',GradientType=0 ); /* IE6-9 */

    font-size: .75rem;
    padding: 1.5rem 0;

    @include mobile() {
      padding-left: 2rem;
      padding-right: 2rem;
      text-align: center;
    }

    a {
      color: gray('200');
      text-decoration: underline;
      &:hover {
        color: theme-color('primary');
      }
    }
    p {
      margin-bottom: .75rem;
      opacity: .7;
      &:last-child { margin-bottom: 0; }
    }

    .logo-white {
      display: block;
      padding: 100% 0 0;
      width: 100%;
      font-size: 0;
      white-space: nowrap;
      position: relative;
      &::before {
        content: '';
        display: block;
        background: transparent url('../../assets/images/roulezrose-logo-mono.png') no-repeat center center;
        background-size: contain;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    .columns {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

  }

  .title {
    color: $white;
    font-size: 1.35rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
    em {
      font-style: normal;
      font-weight: 400;
      font-family: $headings-font-family;
    }
  }

  .menu {
    ul {
      margin: 0;
      li {
        display: block;
        border-bottom: 1px solid gray('700');
        padding-top: .6rem;
        a {
          display: inline-block;
          vertical-align: bottom;
          border-bottom: 2px solid gray('700');
          padding: .2rem .5rem .2rem 0;
          font-size: $font-size-sm;
          color: $white;
          transition: all .3s ease;
          &:hover {
            color: theme-color('primary');
            border-bottom-color: theme-color('primary');
          }
        }
      }
    }
  }

  .newsletter {
    text-align: center;
    .nf-form-fields-required { display: none; }
    input[type="email"] {
      background-color: transparent;
      color: $white;
    }
    .button {
      height: 2.8rem;
    }
  }

  .sociaux {
    text-align: center;
    @include until($tablet) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
    @include tablet() {
      text-align: right;
    }
  }
  a.social {
    margin: 0 1rem;
    width: 3.5rem;
    height: 3.5rem;
    line-height: 3.5rem;
    border-radius: 50%;
    font-size: 1.75rem;
    color: $white;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    background-color: theme-color('secondary');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    transition: all .3s ease;
    &:hover {
      color: $white;
      background-color: theme-color('primary') !important;
    }
    &.social-facebook {
      $grc: #355184;
      background: -moz-linear-gradient(45deg, rgba($grc,0) 0%, rgba($grc,1) 80%, rgba($grc,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(45deg, rgba($grc,0) 0%,rgba($grc,1) 80%,rgba($grc,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(45deg, rgba($grc,0) 0%,rgba($grc,1) 80%,rgba($grc,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      background-color: #095e5c;
    }
    &.social-instagram {
      $grc: #e4794f;
      background: -moz-linear-gradient(45deg, rgba($grc,1) 0%, rgba($grc,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(45deg, rgba($grc,1) 0%,rgba($grc,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(45deg, rgba($grc,1) 0%,rgba($grc,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      background-color: #9f33af;
    }
    &.social-twitter {
      $grc: #2ba8db;
      background: -moz-linear-gradient(45deg, rgba($grc,0) 0%, rgba($grc,1) 80%, rgba($grc,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(45deg, rgba($grc,0) 0%,rgba($grc,1) 80%,rgba($grc,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(45deg, rgba($grc,0) 0%,rgba($grc,1) 80%,rgba($grc,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      background-color: #2bd7d9;
    }
  }

}
