

#main-container .sidebar .widget {

  margin-bottom: 2rem;
  padding: 2rem;
  background: theme-color('dark') url(../../assets/images/roulezrose-bg-footer.png) no-repeat top center;
  background-size: auto auto;
  color: $white;
  background-size: cover;
  // box-shadow: 3px -3px 0 theme-color('primary');
  .widget-title {
    font-family: $headings-font-family;
    margin-bottom: .75rem;
    font-size: 2.15rem;
    text-shadow: 1px -1px 0 theme-color('primary');
    line-height: .85;
    &::after {
      content: '';
      display: block;
      margin-top: 1rem;
      width: 120px;
      height: 2px;
      background-color: theme-color('primary');
    }
    em {
      font-family: $font-family-base;
      font-weight: 300;
      text-transform: uppercase;
      font-style: normal;
      font-size: .75em;
    }
  }
  ul {
    padding-left: .5rem;
    margin: 0 0 .25rem;
    li {
      a { color: $white; &:hover { color: theme-color('primary'); } }
    }
  }
  &:last-child { margin-bottom: 0; }

  &.widget_randos_widget {
    padding: 0;
    background: transparent;
    .widget-title {
      color: theme-color('dark');
    }
    .rando.rando-teaser {
      width: 230px;
      height: 230px;
      border: none;
      margin: 2rem auto 0;
    }
    .owl-nav {
      position: absolute;
      top: -2rem;
      right: 0;
      .owl-prev, .owl-next {
        color: theme-color('primary');
        display: inline-block;
        vertical-align: top;
        line-height: 2rem;
        width: 2rem;
        border-radius: 50%;
        text-align: center;
        padding: 0;
        background-color: gray('200');
        transition: all .3s ease;
        &:hover {
          background-color: theme-color('primary');
          color: $white;
        }
      }
      .owl-next {
        margin-top: 1rem;
        margin-left: -.5rem;
      }
    }
  }

}
