


/******
 ** POSTS
 */
.tile-teaser {
  background-color: $white;
  background-position: left top;
  background-size: 120% 210%;
  background-repeat: no-repeat;
  color: theme-color('dark');
  box-shadow: 0 10px 16px -2px rgba(theme-color('dark'), .6);
  border-radius: 2px;

  > .post,
  > .page,
  > .rando {
    height: 100%;
    position: relative;
    > .columns {
      margin-bottom: 0 !important;
    }
  }
  .visuel {
    font-size: 0;
    img { width: 100%; }
    a { display: block; }
  }
  .texte {
    padding: 1.25rem 2rem;
  }
  .entry-title {
    color: theme-color('primary');
    font-size: $h4-font-size;
    font-weight: 700;
    margin-bottom: .75rem;
    line-height: 1;
  }
  .updated, .categories {
    color: theme-color('secondary');
    font-weight: 300;
    text-transform: uppercase;
    border-bottom: 2px solid theme-color('secondary');
    font-size: .9rem;
    padding: 0 5px;
  }
  .categories {
    &, a { color: gray('500'); }
    a {
      position: relative;
      z-index: 10;
      &:hover {
        color: $white;
      }
    }
    border-bottom-color: gray('200');
  }
  hr {
    border-color: theme-color('secondary');
    margin-top: 0;
    margin-bottom: 1.25rem;
  }

  .global-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-size: 0;
    color: transparent;
  }

  &, .entry-title, .updated, .categories, hr, .visuel {
    transition: all .3s ease;
  }

  &:hover {
    background-color: theme-color('primary');
    background-image: $gradient2;
    color: $white;
    box-shadow: 0 12px 20px 2px rgba(theme-color('dark'), .6);
    .entry-title {
      color: $white;
    }
    .updated, .categories {
      color: $white;
      border-bottom-color: $white;
    }
    hr {
      border-color: $white;
    }
    .visuel {
      opacity: .8;
    }
  }

}





/**
 * RANDONNEES
 */

.iziModal-overlay {
  background-color: rgba(theme-color('primary'),.5) !important;
  background-image: $gradient2;
  background-size: cover;
}
.rando-modal.iziModal {
  border-radius: 0 !important;
}

.rando {

  .rando-annule-wrapper {
    text-align: center;
    padding: 2rem;
    background-color: theme-color('secondary');
    color: $white;
    font-family: $headings-font-family;
    font-size: 2rem;
    &::after {
      content: ':(';
      display: block;
      transform: rotate(60deg);
      margin-top: -1rem;
      margin-left: 2rem;
    }
  }


  &.rando-mini {
    position: relative;
    text-align: center;
    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 90px; bottom: 0;
      left: 50%; margin-left: -75px;
      width: 150px;
      transition: background .3s ease, border .3s ease;
      background: repeating-linear-gradient(
        -45deg,
        #eaf9f8,
        #eaf9f8 4px,
        rgba(0,0,0,0) 4px,
        rgba(0,0,0,0) 16px
      );
      background-position: center top;
    }
    > a {
      display: inline-block;
      vertical-align: bottom;
      width: 150px;
      text-align: center;
      border-bottom: 6px solid theme-color('dark');
      position: relative;
      z-index: 2;
      transition: border .3s ease;
      .date {
        display: inline-block;
        vertical-align: bottom;
        border: 1px solid theme-color('primary');
        color: theme-color('primary');
        border-radius: 1.5rem;
        font-size: $font-size-sm;
        letter-spacing: .04rem;
        margin: 2rem 0 1rem;
        padding: .3rem 1rem;
        font-weight: 900;
        i { margin-left: .5em; }
        text-transform: lowercase;
      }
      .rando-pictos { display: block; }
      .picto {
        display: inline-block;
        vertical-align: bottom;
        width: 150px;
        height: 150px;
        @include until( $widescreen ) {
          width: 130px;
          height: 130px;
        }
        border-radius: 50%;
        background-color: theme-color('primary');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 60px auto;
        border: 20px solid $white;
        box-shadow: -4px 4px 6px rgba( theme-color('dark'), .2 );
        &.niveau-vert { background-color: theme-color('success'); }
        &.niveau-bleu { background-color: theme-color('info'); }
        &.niveau-orange { background-color: theme-color('warning'); }
        &.niveau-noir { background-color: theme-color('dark'); }
        &.picto-petite { background-image: url('../../assets/images/picto-fleche-petite.png'); }
        &.picto-grande { background-image: url('../../assets/images/picto-fleche-grande.png'); }
        &.picto-double { background-image: url('../../assets/images/picto-fleche-double.png'); }
        transition: transform .8s ease;
        &:hover {
          transform: rotate(360deg);
        }
        &.picto-double:hover { transform: rotate(720deg); }
      }
    }
    &:hover {
      &::after {
        background-position: center -20px;
      }
      > a {
        border-bottom-color: theme-color('secondary');
        .date {
          border-color: theme-color('secondary');
          color: theme-color('secondary');
        }
      }
    }
    &.rando-double {
      &::after {
        width: 360px;
        margin-left: -180px;
      }
      > a {
        width: 360px;
        .picto:last-child { margin-left: 60px; }
      }
      @include until( $widescreen ) {
        &::after {
          width: 340px;
          margin-left: -170px;
        }
        > a {
          width: 340px;
          .picto:last-child { margin-left: 40px; }
        }
      }
    }
    &.rando-annule {
      .date {
        border-radius: 3rem;
        margin: 1rem 0 .8rem;
      }
      .rando-annule-wrapper {
        padding: 0;
        font-size: 1.2rem;
        font-weight: 400;
        color: theme-color('secondary');
        background-color: transparent;
        line-height: 1rem;
        margin-bottom: 0.2rem;
        &::after {
          margin-left: .7rem;
          display: inline-block;
        }
      }
    }
  }


  &.rando-teaser {
    width: 260px;
    height: 260px;
    background-color: gray('200');
    display: block;
    border-radius: 50%;
    position: relative;
    border: 15px solid gray('200');
    margin: 0 auto;
    .picto {
      position: absolute;
      left: 50%;
      margin-top: -1.75rem;
      background-color: theme-color('dark');
      width: 2.75rem;
      height: 2.75rem;
      margin-left: -1.375rem;
      border-radius: 50%;
      z-index: 5;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 30px auto;
      &::after {
        content: "";
        position: absolute;
        border: 5px solid transparent;
        border-top-color: theme-color('dark');
        bottom: -9px;
        left: 50%;
        margin-left: -5px;
      }
      &.picto-petite { background-image: url('../../assets/images/picto-fleche-petite.png'); }
      &.picto-grande { background-image: url('../../assets/images/picto-fleche-grande.png'); }
      &.picto-double { background-image: url('../../assets/images/picto-fleche-double.png'); }
    }
    .inside {
      display: block;
      overflow: hidden;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      position: relative;
      .i-left, .i-right {
        color: rgba( theme-color('dark'), .1 );
        font-size: 6rem;
        position: absolute;
        top: 80px;
        z-index: 3;
      }
      .i-left { left: -1.5rem; }
      .i-right { right: -2rem; top: 120px; }
    }
    .infos {
      background-color: $white;
      .widget_randos_widget &,
      body.page-template-page-randos & { background-color: gray('200'); }
      text-align: center;
      display: block;
      padding: 2rem 2rem 0;
      height: 90px;
      color: theme-color('dark');
      box-shadow: 0 0 12px rgba(theme-color('dark'), .3);
      position: relative;
      z-index: 2;
      span { display: block; }
      .date { font-weight: 700; }
    }
    .rando-annule-wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 3;
      background-color: rgba(theme-color('secondary'),.7);
      padding-top: 4.5rem;
      transform: skew(0, -6deg);
    }
    .boucles {
      text-align: center;
      display: block;
      padding: 0;
      height: 140px;
      position: relative;
      z-index: 1;
      .boucle {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        color: $white;
        padding: 20px 10px 0;
        z-index: 1;
        &.niveau-vert { background-color: theme-color('success'); }
        &.niveau-bleu { background-color: theme-color('info'); }
        &.niveau-orange { background-color: theme-color('warning'); }
        &.niveau-noir { background-color: theme-color('dark'); }
        span {
          display: block;
          font-weight: 700;
          em {
            font-style: normal;
            font-weight: 300;
            font-size: .8em;
            margin-right: 10px;
          }
          sup {
            top: -.35em;
            font-weight: 400;
            text-transform: uppercase;
            margin: 0 .1em;
            font-size: .675em;
          }
          small {
            display: block;
            font-size: .65em;
            margin-top: -.35em;
          }
        }
        .distance {
          border-top: 1px dashed rgba($white,.6);
          padding-top: 3px;
          margin-top: 6px;
        }
      }
      &.simple {
        .boucle {
          left: 0;
          right: 0;
        }
      }
      &.double {
        .boucle:first-child {
          left: 0;
          right: 50%;
          text-align: right;
        }
        .boucle:last-child {
          left: 50%;
          right: 0;
          text-align: left;
        }
      }
      &.with-thematique {
        .boucle {
          background-color: rgba(theme-color('dark'),.8);
          &.niveau-vert { background-color: rgba(theme-color('success'), .85); }
          &.niveau-bleu { background-color: rgba(theme-color('info'), .85); }
          &.niveau-orange { background-color: rgba(theme-color('warning'), .85); }
          &.niveau-noir { background-color: rgba(theme-color('dark'), .85); }
          opacity: 0;
        }
      }
    }

    &, .infos, .picto, .picto::after, .boucles .boucle {
      transition: all .3s ease;
    }
    &:hover {
      border-color: theme-color('primary');
      .picto {
        background-color: theme-color('primary');
        transform: rotate(360deg);
        &.picto-double { transform: rotate(720deg); }
        &::after { border-top-color: theme-color('primary'); }
      }
      .infos {
        background-color: gray('200');
      }
      .boucles .boucle {
        opacity: 1;
      }
    }
  }


  &.rando-full {

    h2.title {
      padding: 1rem;
      font-family: $font-family-base;
      font-weight: 700;
      em { font-style: normal; font-weight: 400; }
      i { display: inline-block; vertical-align: middle; margin-right: 1rem; }

      .close-modal {
        position: absolute;
        right: 1rem;
        top: .95rem;
        color: gray('400');
        &:hover { color: theme-color('primary'); }
      }
    }

    .rando-annule-wrapper {
      margin-bottom: 2rem;
    }

    .description {
      padding: 1rem 4.5rem 2rem;
      p:last-child { margin-bottom: 0; }
    }

    .infos {
      .info-type {
        background-color: gray('100');
        padding: 1rem;
        height: 100%;
        .columns {
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          height: 100%;
        }
        .title {
          margin-bottom: 0;
          text-transform: uppercase;
          font-weight: 700;
          color: theme-color('dark');
          font-size: $h4-font-size;
        }
        .picto {
          height: 90px;
          margin: 1rem 0;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          &.picto-petite { background-image: url('../../assets/images/picto-fleche-petite-noir.png'); }
          &.picto-grande { background-image: url('../../assets/images/picto-fleche-grande-noir.png'); }
          &.picto-double { background-image: url('../../assets/images/picto-fleche-double-noir.png'); }
        }
      }
      .info-boucle {
        color: $white;
        overflow: hidden;
        padding: 1rem;
        text-align: center;
        position: relative;
        height: 100%;
        .title {
          background-color: rgba( theme-color('dark'), .1 );
          font-weight: 300;
          text-transform: uppercase;
          padding: .5rem 1rem;
          margin: -1rem -1rem 1rem;
          color: $white;
          font-family: $font-family-base;
          font-size: $h4-font-size;
          em { font-style: normal; font-weight: 400; font-family: $headings-font-family; }
        }
        ul {
          text-align: left;
          padding: 0 1.5rem 0 2rem;
          li { list-style: outside; margin-bottom: .75rem; font-size: .95rem; }
        }
        .i-left, .i-right {
          color: rgba( theme-color('dark'), .1 );
          font-size: 6rem;
          position: absolute;
          top: 2.5rem;
        }
        .i-left { left: -2.5rem; }
        .i-right { right: -4rem; }
        &.niveau-vert { background-color: theme-color('success'); }
        &.niveau-bleu { background-color: theme-color('info'); }
        &.niveau-orange { background-color: theme-color('warning'); }
        &.niveau-noir { background-color: theme-color('dark'); .title { background-color: $black; } }
      }

      &.double {
        .info-type {
          .picto {
            height: 70px;
          }
        }
        .info-boucle {
          ul {
            padding: 0 .5rem 0 1rem;
          }
        }
      }
    }

    .map {
      background-color: gray('100');
      // @include from($desktop) {
      //   background: gray('100') url('../../assets/images/roulezrose-bg-modal.jpg') no-repeat center bottom;
      //   background-size: 100% auto;
      // }
      padding: 1rem 1.5rem 1.5rem;
      .title {
        font-family: $font-family-base;
        font-size: $h4-font-size;
        font-weight: 400;
        margin-bottom: 1rem;
        text-transform: uppercase;
        em { font-style: normal; font-weight: 400; font-family: $headings-font-family; }
      }
      img { width: 100%; }
    }

  }

}

.iziModal-content {
  .preloading {
    min-height: 400px;
  }
  .rando-full {
    padding: 1.5rem;
    h2.title {
      margin: -1.5rem -1.5rem 1.5rem;
      border-bottom: 1px solid theme-color('dark');
    }
    .map {
      margin: 0 -1.5rem -1.5rem;
    }
    @include mobile() {
      padding-top: 7.5rem;
      min-height: 100vh;
      h2.title {
        font-size: 1.5rem;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background: $white;
        margin: 0;
        z-index: 100;
        em {
          display: block;
          margin-left: 3.5rem;
        }
      }
      .description {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
  }
}




/************* Pagination */

.wp-pagenavi {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  > * {
    height: 2rem;
    line-height: 2rem;
    padding: 0 1rem;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    margin: 0 3px;
    border-radius: 1.25rem;
    border: 1px solid gray('400');
    color: gray('500');
    transition: all .3s ease;
    font-size: .85rem;
  }
  a.page, .current, .extend {
    width: 2rem;
    padding: 0;
  }
  a:hover {
    background-color: theme-color('primary');
    border-color: theme-color('primary');
    color: $white;
  }
  .current {
    background-color: theme-color('primary');
    border-color: theme-color('primary');
    color: $white;
  }
  .pages, .extend {
    border-color: transparent;
  }
}



/************* Galeries */

.gallery-nav {
  .button {
    &::after { display: none; }
  }
}

.post .owl-carousel.gallery {
  .item {
    margin: 0;
    padding: .25rem;
    background-color: theme-color('dark');
    a {
      display: block;
      position: relative;
      overflow: hidden;
      padding: 50% 0 0;
      font-size: 0;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
      }
      &::after {
        @extend .fa;
        content: $fa-var-search-plus;
        font-size: 6rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%) scale(0);
        color: $white;
      }
      img, &::after { transition: all .3s ease; }
      &:hover {
        img { opacity: .8; }
        &::after {
          transform: translate(-50%,-50%) scale(1);
        }
      }
    }
    .caption {
      display: block;
      position: absolute;
      bottom: 4rem;
      font-size: 1rem;
      left: 50%;
      text-align: center;
      max-width: 70%;
      background-color: rgba(theme-color('dark'),.5);
      color: gray('200');
      padding: .5rem 2rem;
      transform: translate(-50%,0);
    }
  }

  .owl-nav {
    padding: 0;
    .owl-next, .owl-prev {
      position: absolute;
      z-index: 10;
      top: 50%;
      width: 3rem;
      height: 3rem;
      line-height: 3rem;
      margin-top: -1.5rem;
      background-color: gray('200');
      color: theme-color('dark');
      transition: all .3s ease;
      text-align: center;
      border-radius: 50%;
      &:hover {
        background-color: theme-color('primary');
        color: $white;
      }
    }
    .owl-prev { left: -1.5rem; }
    .owl-next { right: -1.5rem; }
  }

  .owl-dots {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    text-align: center;
    z-index: 10;
    .owl-dot {
      display: inline-block;
      vertical-align: middle;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%;
      background-color: theme-color('dark');
      margin: 0 .25rem;
      &:hover { background-color: $white; }
      &.active { background-color: theme-color('primary'); }
    }
  }

}
