// Search form
/*.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}
*/

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
select {
  height: 3rem;
  padding: 1rem;
  border: 1px solid gray('700');
}


.nf-form-cont {

  text-align: center;
  margin: 0 0 6rem;

  .nf-before-form-content {
    margin-bottom: 2rem;
  }

  .nf-field-label {
    margin-bottom: 10px;
    text-align: center;
    margin-left: 2rem;
    font-family: $headings-font-family;
    font-size: 1.5rem;
    font-weight: 400;
  }

  .nf-error-msg, .ninja-forms-req-symbol {
    color: theme-color('primary');
  }

  .field-wrap {
    &.textbox-wrap,
    &.email-wrap,
    &.textarea-wrap {
      .nf-element {
        line-height: 3rem;
        padding: 0 2rem;
        border-radius: 2rem;
        font-weight: 700;
        letter-spacing: .05em;
        border: 1px solid gray('400');
        width: 100%;
        &:hover, &:focus { border-color: theme-color('primary'); }
      }
    }
  }
  .submit-wrap {
    .nf-element {
      @extend .button.is-primary;
      line-height: 3rem;
      padding: 0 2rem;
      border-radius: 2rem;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: .05em;
      border: 1px solid transparent !important;
      width: auto !important;
      cursor: pointer;
      // margin-left: -4rem;
      footer & {
        margin-left: auto;
      }
    }
  }

}
